import React from 'react';
import './App.css';
import { AppClient } from './api/AppClient';
import { ApiClient } from './api/ApiClient';
import { withMsal, WithMsalProps } from "@azure/msal-react";
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';

interface IAppProps extends WithMsalProps {
}

interface IAppState {
  stato: string;
}

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      stato: ''
    };

  }
  public componentDidMount() {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (!isAuthenticated) {
      // Devo fare il login
      this.props.msalContext.instance.loginPopup().then((resp) => {
        console.log('Login ok', resp);
        this.setState({ stato: 'Login ok: ' + JSON.stringify(resp) });
        if (resp.accessToken) {
          this.getApiInfo(resp.accessToken);
        }
      }).catch((reason) => {
        console.log('Login ERROR', reason);
        this.setState({ stato: 'Login ERROR: ' + JSON.stringify(reason) });
      });
    } else {
      let account = this.props.msalContext.accounts[0] as AccountInfo;
      // Devo recuperare il token
      //this.getApiInfo(accessToken);
      console.log('Collegato', account);
      this.setState({ stato: 'Collegato: ' + JSON.stringify(account) });
      //this.getApiInfo(account);
    }
  }

  private async getApiInfo(accToken: string) {

    try {
      /*
      let authResp = await this.props.msalContext.instance.acquireTokenPopup({
        scopes: ['User.Read', 'https://citofono.azurewebsites.net/Citofoni.All'],
        account: account
      });
      console.log(authResp);     
      */
      

      /*
      let appCli = new AppClient();
      let info = await appCli.getApiInfo();
      console.log(info);
      */
      let apiCli = new ApiClient(accToken);
      //await apiCli.getAccessToken(authResp.idToken, authResp.accessToken);
      let homeInfo = await apiCli.getHomeData()
      console.log(homeInfo);
      // Sono riuscito ad ottenere le info
      this.setState({ stato: 'OK - ' + JSON.stringify(homeInfo) });
    } catch (reason) {
      // Chiamata api Home fallita.
      console.log('Chiamata api home fallita.', reason);
      this.setState({ stato: 'Errore chiamata api home: ' + JSON.stringify(reason) });
    }
  }

  public render(): JSX.Element {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Applicazione Admin Citofono</h1>
          <p>Stato: <b>{this.state.stato}</b></p>
        </header>
      </div>
    );
  }
}

export default withMsal(App);