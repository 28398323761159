import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_APP_ID || '',
    authority: process.env.REACT_APP_AUTH_URL
  }
};

const pca = new PublicClientApplication(configuration);


ReactDOM.render(
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>,
  document.getElementById('root')
);

