import { IApiInfo } from "./AppClient";
import { ErrorResponse } from "./ErrorResponse";

export interface IHomeResponse {
    [name: string]: string | undefined;
}

export class ApiClient {

    // https://citofono.azurewebsites.net/api
    private baseUrl: string = process.env.REACT_APP_API_BASE_URL || '/';
    private apiKey: string | undefined = process.env.REACT_APP_API_KEY;

    private accToken: string;

    constructor(accToken: string) {
        this.accToken = accToken;
    }

    private getURL(apiPath: string, params: string[][]): string {
        let qParams = new URLSearchParams(params);
        let retUrl = new URL(this.baseUrl + apiPath);
        if (this.apiKey) {
            retUrl.searchParams.append('code', this.apiKey);
        }
        qParams.forEach((val, key) => {
            retUrl.searchParams.append(key, val);
        })

        return retUrl.toString();
    }

    public async getAccessToken(idToken: string, accToken: string) {
        let data = {
            //id_token: idToken,
            access_token: accToken
        };
        let bUrl = new URL(this.baseUrl);
        const response = await fetch(bUrl.origin+'/.auth/login/aad', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        if (response.ok) {
            let resp = await response.json();
            console.log(resp);
            if (resp) {
                this.accToken = resp.authenticationToken;
            }
            return resp;
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }

    }
    public async getHomeData(): Promise<IHomeResponse> {
        const response = await fetch(this.getURL('/citofono/admin/home', []), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                //'X-ZUMO-AUTH' : this.accToken,
                'Authorization' : 'Bearer ' + this.accToken
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        if (response.ok) {
            return response.json(); // parses JSON response into native JavaScript objects
        } else {
            console.log('Chiamata fallita: ' + response.status + ' - ' + response.statusText);
            if (response.status === 401) {
                // Non autenticato
                throw new ErrorResponse('Non autenticato.', response.status, response.statusText);
            } else {
                throw new ErrorResponse('Errore: ' + response.status + ' - ' + response.statusText, response.status, response.statusText);
            }
        }
    }
}